import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import ProfileLayout from "../views/Layout/ProfileLayout.vue";
import DashboardLayoutVr from "../views/Layout/DashboardLayoutVr.vue";
import PageLayout from "../views/Layout/PageLayout";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
import AuthIllustrationLayout from "../views/Layout/AuthIllustrationLayout";
import store from "@/store";
import {checkTokenStatus} from "@/services/auth.service";

// Dashboard pages
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");
const Automotive = () => import("../views/Dashboard/Automotive.vue");
const ClientDashboard = () => import("../views/Dashboard/ClientDashboard.vue");
const AdminDashboard = () => import("../views/Dashboard/AdminDashboard.vue");
const VrDefault = () => import("../views/Dashboard/VrDefault.vue");
const VrInfo = () => import("../views/Dashboard/VrInfo.vue");
const Crm = () => import("../views/Dashboard/Crm.vue");
const GettingStarted = () => import("../views/Dashboard/GettingStarted.vue");

// Invoice pages
const ViewInvoice = () => import("../views/Pages/Invoices/View.vue");

// Pages
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const Rtl = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Rtl.vue");
const ProfileOverview = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Profile/ProfileOverview.vue"
  );
const Teams = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Teams.vue");
const Notices = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Notices.vue");
const Projects = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile/Projects.vue");
const Reports = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/Reports.vue");
const NewUser = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Users/NewUser.vue");
const Settings = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Settings.vue");
const Billing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Billing.vue");
const Invoice = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Account/Invoice.vue");
const Timeline = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Projects/Timeline.vue");
const Charts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Charts.vue");
const Alerts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Alerts.vue");
const Notifications = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Notifications.vue");
const Register = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Auth/Register.vue"
  );
const Search = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Auth/Search.vue"
        );
const SignUpCover = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Cover.vue"
  );
const Login = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Auth/Login.vue"
  );

// Applications
const Kanban = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Kanban.vue");
const Wizard = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Wizard.vue");
const Datatables = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Datatables.vue");
const Calendar = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Applications/Calendar.vue");

// Ecommerce
const NewProduct = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Client/Products/NewProduct.vue"
  );
const Deregister = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Client/Products/Deregister.vue"
        );
const EditProduct = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Client/Products/EditProduct.vue"
  );
const ProductPage = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Client/Products/ProductPage.vue"
  );
const OrderList = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Client/Orders/OrderList.vue"
  );
const ReturnsList = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Client/Orders/Returns.vue"
  );
const LedgerDownload = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Client/Orders/LedgerDownload.vue"
        );
const Read = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Client/Orders/Read.vue"
  );
const AdminReturnsList = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Client/Orders/AdminReturns.vue"
        );
const AdminLedgerRecords = () =>
  import(
      /* webpackChunkName: "pages" */ "@/views/Client/Orders/AdminLedgerRecords.vue"
      );
const USDAdminLedgersList = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Client/Orders/USDAdminLedgers.vue"
        );
const ZIGAdminLedgersList = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Client/Orders/ZIGAdminLedgers.vue"
        );
const AdminCompanyList = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Client/Orders/AdminCompanies.vue"
        );
const AdminRegisterList = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Client/Orders/AdminRegister.vue"
        );
const AdminUserList = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Client/Orders/AdminUsers.vue"
        );
const AdminReturnsView = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Client/Orders/AdminReturnsView.vue"
        );
const AdminOrganisationView = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Client/Orders/AdminOrganisationView.vue"
        );
const ReturnsAdd = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Client/Orders/ReturnsAdd.vue"
        );
const USDLedgerAdd = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Client/Orders/USDLedgerAdd.vue"
        );
const ZIGLedgerAdd = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Client/Orders/ZIGLedgerAdd.vue"
        );
const Newsletter = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Client/Orders/News.vue"
        );
const AdminNewsletter = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Client/Orders/AdminNews.vue"
        );
const ManageReturns = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Client/Orders/OrderDetails.vue"
  );

Vue.use(VueRouter);

let InvoicePages  = {
  path: "/",
  component: ProfileLayout,
  name: "Invoice",
  children: [
    {
      path: "/pages/invoices/view",
      name: "ViewInvoices",
      component: ViewInvoice,
      meta: {
        groupName: "Invoice",
      },
    },
  ],
};
let vrPages = {
  path: "/",
  component: DashboardLayoutVr,
  name: "Vr",
  children: [
    {
      path: "/pages/dashboards/vr/vr-default",
      name: "VrDefault",
      component: VrDefault,
      meta: {
        groupName: "Dashboards",
      },
    },
    {
      path: "/pages/dashboards/vr/vr-info",
      name: "VrInfo",
      component: VrInfo,
      meta: {
        groupName: "Dashboards",
      },
    },
  ],
};

let profilePages = {
  path: "/",
  component: ProfileLayout,
  name: "Profile",
  children: [
    {
      path: "/pages/pages/profile/overview",
      name: "ProfileOverview",
      component: ProfileOverview,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/teams",
      name: "Teams",
      component: Teams,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/notice",
      name: "Notice",
      component: Notices,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/profile/projects",
      name: "Project",
      component: Projects,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let userPages = {
  path: "/",
  component: DashboardLayout,
  name: "Users",
  children: [
    {
      path: "/pages/pages/users/reports",
      name: "Reports",
      component: Reports,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/users/new-user",
      name: "NewUser",
      component: NewUser,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let accountPages = {
  path: "/",
  component: DashboardLayout,
  name: "Account",
  children: [
    {
      path: "/pages/pages/account/settings",
      name: "Settings",
      component: Settings,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/billing",
      name: "Billing",
      component: Billing,
      meta: {
        groupName: "Pages",
      },
    },
    {
      path: "/pages/pages/account/invoice",
      name: "Invoice",
      component: Invoice,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let projectsPages = {
  path: "/",
  component: DashboardLayout,
  name: "Projects",
  children: [
    {
      path: "/pages/pages/projects/timeline",
      name: "Timeline",
      component: Timeline,
      meta: {
        groupName: "Pages",
      },
    },
  ],
};

let applicationPages = {
  path: "/",
  component: DashboardLayout,
  name: "Application",
  children: [
    {
      path: "/pages/applications/kanban",
      name: "Kanban",
      component: Kanban,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/wizard",
      name: "Wizard",
      component: Wizard,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/datatables",
      name: "Datatables",
      component: Datatables,
      meta: {
        groupName: "Applications",
      },
    },
    {
      path: "/pages/applications/calendar",
      name: "Calendar",
      component: Calendar,
      meta: {
        groupName: "Applications",
      },
    },
  ],
};

let ecommercePages = {
  path: "/",
  component: ProfileLayout,
  name: "Ecommerce",
  children: [
    {
      path: "/pages/ecommerce/products/new-product",
      name: "NewProduct",
      component: NewProduct,
      meta: {
        groupName: "Ecommerce",
      },
    },
    {
      path: "/pages/ecommerce/products/deregister",
      name: "Deregister",
      component: Deregister,
      meta: {
        groupName: "Ecommerce",
      },
    },
  ],
};

let pricingPage = {
  path: "/",
  component: PageLayout,
  name: "Pricing Page",
  children: [
    {
      path: "/pages/pages/pricing-page",
      name: "Pricing",
      component: Pricing,
    },
  ],
};

let authBasicPages = {
  path: "/",
  component: AuthBasicLayout,
  name: "Authentication Basic",
  children: [
    {
      path: "register",
      name: "Register",
      component: Register,
    },
    {
      path: "search",
      name: "SearchOrganisation",
      component: Search,
    },
  ],
};

let authCoverPages = {
  path: "/",
  component: AuthCoverLayout,
  name: "Authentication Cover",
  children: [
    {
      path: "/pages/authentication/signup/cover",
      name: "SignUpCover",
      component: SignUpCover,
    },
  ],
};

let authIllustrationPages = {
  path: "/",
  component: AuthIllustrationLayout,
  name: "Authentication Illustration",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
  ],
};

const routes = [
  {
    path: "/",
    name: "Dashboard",
    redirect: "/client-dashboard",
    component: DashboardLayout,
    beforeEnter: async (to, from, next) => {
      if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
        next("/login");
      }
      else if (store.state.client === true){
        next("/client-returns");
      }
      else {
        next();
      }
    },
    children: [
      {
        path: "/client-dashboard",
        name: "Home",
        component: ClientDashboard,
        meta: {
          groupName: "Dashboards",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          }
          else if (store.state.is_admin === false){
            next("/client-returns");
          }
          else {
            next();
          }
        }
      },
      {
        path: "/admin-dashboard",
        name: "Home",
        component: AdminDashboard,
        meta: {
          groupName: "Dashboards",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/pages/dashboards/crm",
        name: "Crm",
        component: Crm,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/rtl",
        name: "RTL",
        component: Rtl,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/charts",
        name: "Charts",
        component: Charts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/alerts",
        name: "Alerts",
        component: Alerts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/pages/notifications",
        name: "Notifications",
        component: Notifications,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "getting-started",
        name: "Getting Started",
        component: GettingStarted,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/pages/ecommerce/products/edit-product",
        name: "EditProduct",
        component: EditProduct,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/products/product-page",
        name: "ProductPage",
        component: ProductPage,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/pages/ecommerce/orders/list",
        name: "Invoices",
        component: OrderList,
        meta: {
          groupName: "Ecommerce",
        },
      },
      {
        path: "/read/:id",
        name: "Read",
        component: Read,
        meta: {
          groupName: "Newsletter",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/client-returns",
        name: "Returns",
        component: ReturnsList,
        meta: {
          groupName: "Returns",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/ledger-download/:id",
        name: "Ledger Download",
        component: LedgerDownload,
        meta: {
          groupName: "Ledgers",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/admin-returns",
        name: "Admin Returns",
        component: AdminReturnsList,
        meta: {
          groupName: "Admin",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/admin-usd-ledgers",
        name: "Admin USD Ledgers",
        component: USDAdminLedgersList,
        meta: {
          groupName: "Admin",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/admin-ledger-records/:id",
        name: "Admin Ledger Records",
        component: AdminLedgerRecords,
        meta: {
          groupName: "Admin",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/admin-zig-ledgers",
        name: "Admin ZiG Ledgers",
        component: ZIGAdminLedgersList,
        meta: {
          groupName: "Admin",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/admin-add-usd-ledgers",
        name: "Admin Add USD Ledgers",
        component: USDLedgerAdd,
        meta: {
          groupName: "Admin",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/admin-add-zig-ledgers",
        name: "Admin Add ZiG Ledgers",
        component: ZIGLedgerAdd,
        meta: {
          groupName: "Admin",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/admin-register",
        name: "Admin Register",
        component: AdminRegisterList,
        meta: {
          groupName: "Admin",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/admin-users",
        name: "Admin Users",
        component: AdminUserList,
        meta: {
          groupName: "Admin",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/admin-companies",
        name: "Admin Companies",
        component: AdminCompanyList,
        meta: {
          groupName: "Admin",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/manage-users",
        name: "Manage Users",
        component: AdminCompanyList,
        meta: {
          groupName: "Admin",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/admin-returns-view/:id",
        name: "Comment Returns",
        component: AdminReturnsView,
        meta: {
          groupName: "Returns",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/admin-organisation-view/:id",
        name: "Approve Organisation",
        component: AdminOrganisationView,
        meta: {
          groupName: "Organisations",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/add-return",
        name: "AddReturns",
        component: ReturnsAdd,
        meta: {
          groupName: "Ecommerce",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/newsletters",
        name: "Newsletter",
        component: Newsletter,
        meta: {
          groupName: "Client",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/admin-newsletters",
        name: "Add Newsletter",
        component: AdminNewsletter,
        meta: {
          groupName: "Client",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "/return-payment/:id",
        name: "Manage Returns",
        component: ManageReturns,
        meta: {
          groupName: "Returns",
        },
        beforeEnter: async (to, from, next) => {
          if (store.state.isAuthenticated === false || await checkTokenStatus() === 401) {
            next("/login");
          } else {
            next();
          }
        }
      },
    ],
  },
  pricingPage,
  profilePages,
  applicationPages,
  ecommercePages,
  userPages,
  accountPages,
  projectsPages,
  vrPages,
  authBasicPages,
  authCoverPages,
  authIllustrationPages,
];

const router = new VueRouter({
  routes,
});

export default router;
