<template>
  <v-app>
    <drawer :drawer="drawer"></drawer>
    <v-main>
      <div
        @click="drawer = false"
        v-if="drawer"
        class="position-absolute drawer-state"
      ></div>
      <v-container fluid>
        <div
          class="
            min-height-150
            border-radius-xl
            overflow-hidden
            position-relative
            mt-6
          "
          :style="`background-image: url(${require('../../assets/img/curved-images/curved0.jpg')}); background-position: 50%; background-size: cover;`"
        >
          <span class="mask bg-gradient-secondary opacity-5"></span>
          <app-bar-profile
            background="bg-transparent"
            has-bg
            @drawer-toggle="drawer = $event"
            :toggle-active="drawer"
          ></app-bar-profile>
        </div>
        <v-card
          class="
            px-4
            py-4
            mx-6
            overflow-hidden
            border-radius-xl
            blur
            shadow-blur
            mt-n16
          "
        >
          <v-row>
            <v-col cols="auto">
              <v-avatar width="74" height="74" class="shadow border-radius-lg">
                <img
                  src="@/assets/img/bruce-mars.jpg"
                  alt="Avatar"
                  class="border-radius-lg"
                />
              </v-avatar>
            </v-col>
            <v-col cols="auto" class="my-auto">
              <div class="h-100">
                <h5 class="mb-1 text-h5 text-typo font-weight-bold">
                  Alec Thompson
                </h5>
                <p class="mb-0 font-weight-bold text-body text-sm">
                  Construction Company
                </p>
              </div>
            </v-col>
            <v-col
              lg="2"
              md="2"
              class="my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3"
            >
              <div class="d-sm-flex justify-between">
                <v-btn
                    @click="goTo()"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                        font-weight-bold
                        text-uppercase
                        btn-default
                        bg-gradient-secondary
                        py-2
                        px-6
                        me-2
                        mb-sm-0 mb-2
                      "
                    color="#5e72e4"
                    small
                >MY PROFILE</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <router-view></router-view>
      </fade-transition>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </v-main>
  </v-app>
</template>
<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

// import DashboardNavbar from './DashboardNavbar.vue';
import { FadeTransition } from "vue2-transitions";
import Drawer from "@/components/Drawer.vue";
import AppBarProfile from "@/components/AppBarProfile.vue";
import ContentFooter from "@/components/Footer.vue";

export default {
  components: {
    ContentFooter,
    FadeTransition,
    Drawer,
    AppBarProfile,
  },
  data() {
    return {
      drawer: null,
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
